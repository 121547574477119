@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');


body {
  margin: 0;
  background: #fbfbfb;
  font-family: 'IBM Plex Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
input,
button,
p {
  font-family: 'IBM Plex Sans', sans-serif !important;
}


.Popular_one {
  display: flex;
}

.Popular-nft {
  background: #747691;
  border: 1px solid #1d1529;
  padding: 10px;
  box-shadow: #fbfbfb;
  margin-left: 10px;
  border-radius: 15px;
}

.user-nft {
  margin-left: 30px;
}

.Popular-nfts {
  background: #351428;
  border: 1px solid #1d1529;
  padding: 10px;
  box-shadow: #fbfbfb;
  margin-left: 30px;
  border-radius: 15px;
}

.ragnars {
  bottom: -120px;
  left: 0;
  right: 0;
  padding: 10px;
  background: #1c1a3c;
  margin-bottom: 15px;
  color: #fff;
  text-align: center;
  border-radius: 0px 0px 15px 15px;
}

.flr_one {
  background: #00000099;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.vandal {
  margin-left: 15px;
}

.flr-size {
  font-size: 15px;
  font-weight: 900;
}

.flr-sizes {
  font-size: 10px;
}







/* Rajaram */
.Tabs_start table thead tr th {
  padding: 10px 5px;
  text-align: center;
}

.Tabs_start table thead {
  background: #1c2039;
}

.Tabs_start table {
  position: relative;
  background-color: rgba(20, 20, 48, 0.5);
  border: 1px solid rgba(116, 118, 145, 0.2);
  border-radius: 8px;
  border-spacing: 0px;
  font-size: 14px;
}

.Tabs_start table tbody tr {
  line-height: 55px;
  text-align: center;
  border: 1px solid #1c2039;
}

.Tabs_start button {
  background: rgba(116, 118, 145, 0.2);
  color: #fff !important;
  margin: 0px 5px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0px 25px;
  height: 48px;
}

.Tabs_start span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  height: 0px !important;
}

img.pht_inner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #7789ff;
  object-fit: cover;
}


.Solana {
  border: none;
  border-radius: 15px;
  background: #10141f !important;
  box-shadow: 0 8px 20px rgb(0 0 0 / 60%);
  color: #fff;
}

.Solana h2 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  line-height: 40px;
}

img.wallet_img {
  filter: invert(1);
  width: 50px;
}

.img_wall {
  background: #1a1d33;
  padding: 20px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border: 2px dotted #fff;
}

.Solana button {
  display: block;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  background-color: #512da8;
  padding: 12px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  color: #fff;
}


.Customize {
  border: none;
  border-radius: 15px;
  background: #10141f !important;
  box-shadow: 0 8px 20px rgb(0 0 0 / 60%);
  color: #fff;
}

.finner_flor span {
  background: #686a81;
  padding: 6px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  text-align: center;
  line-height: 10px;
  margin-right: 5px;
  color: #ffffff;
  font-size: 14px;
}

.finner_flor {
  background: #3a3b48;
  margin: 0 2px 10px;
  padding: 4px 14px;
  border-radius: 35px;
  font-size: 13px;
  display: inline-block;
}

.Customize h5 {
  font-size: 15px;
  padding-bottom: 15px;
  color: #767676;
  font-weight: 500;
}

.range_sliders {
  padding: 20px;
  border: none;
  border-radius: 15px;
  background: #10141f !important;
  box-shadow: 0 8px 20px rgb(0 0 0 / 60%);
  color: #fff;
}

.floor_price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

/* .MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: transparent;
} */

.floor_price span {
  color: #747691;
  font-size: 13px;
  font-weight: 700;
}

.range_sliders button {
  background: #512da8;
  width: 100%;
  margin-top: 10px;
}

.citzen {
  color: #ebe3e3;
  font-size: 12px;
}

.collab-nft {
  width: 80%;
  margin: 0 auto;
}

.collab_two {
  width: 40%;
  padding: 30px;
}

.view {
  border-radius: 8px !important;
  background-color: #bb27e2 !important;
  font-size: 12px !important;
  padding: 6px 12px !important;
  text-decoration: none !important;
  margin-top: 15px !important;

}

.Buy {
  background-color: #bb27e2 !important;
  font-size: 12px !important;
  padding: 5px 5px !important;
  margin-top: 8px !important;
}

.community {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  overflow: hidden;
  margin-bottom: 20px;
}

.card-price {
  font-size: 15px;
}

.user-nft :hover.ragnars {
  bottom: 50% !important;
}

.browse-bck {
  border-radius: 12px;
  background-color: #131722;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.card-name {
  align-items: center;
  color: #fff !important;
  display: flex;
  justify-content: space-between;
}

.proud {
  padding: 10px;
}

.art1 {
  font-size: 12px;
}

.Collabo_start .slick-dots li button:before {
  opacity: 1;
  color: #fff;
}

.Collabo_start .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #ffffff !important;
  border: 1px solid #f73c63;
  border-radius: 55%;
  background: #f73c63;
  width: 15px;
  height: 15px;
  margin-top: 3px;
  line-height: 15px;
}
.browse-nfts img {
  border-radius: 15px 15px 0px 0px;
}
.proud h6 {
  font-size: 18px;
  font-weight: 500;
}
.Philip h2 {
  font-size: 35px;
  font-weight: 600;
  padding-bottom: 15px;
}
.Philip h6 {
  font-size: 19px;
  font-weight: 500;
  line-height: 32px;
}
.Philip h6 span {
  font-weight: 300;
  font-size: 16px;
  color: #b9b9b9;
}
.Philip {
  border: 1px solid #3c3c3c;
  padding: 20px;
  border-radius: 15px;
}
.Potocnik_pic img {
  border-radius: 20px;
  /* height: 700px; */
  object-fit: cover;
}
.Potocnik_pic img {
  border-radius: 20px;
}
.Patterfly img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.jonathan h4 {
  color: #fff;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}
.jonathan h6 {
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  color: #b9b9b9;
}
.Patter {
  background: #bb27e2;
  padding: 10px;
  border-radius: 6px;
  width: fit-content;
}
.Philip hr {
  border: 1px solid #1a1a1a;
}
.Panter img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.buy_now button {
  background: #bb27e2;
  color: #fff;
  padding: 8px 40px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  text-transform:capitalize;
}
.Checkout {
  border: none !important;
  border-radius: 15px;
  background: #10141f !important;
  box-shadow: 0 8px 20px rgb(0 0 0 / 60%);
  color: #fff;
}
.quantity input {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
}
 
.quantity  .MuiInputBase-fullWidth.MuiInputBase-formControl  {
  border: none !important;
  outline: 1px solid #ffff;
}
.Tabs_start table tbody tr a {
  color: #fff;
  text-decoration: none;
}
.user_one img {
  border-radius: 15px 15px 0px 0px;
}

@media (max-width: 991px) {

  .collab-nft {
    width: 100%;
    display: block !important;
  }

  .collab_two {
    width: auto;
    padding: 20px;
  }

  .user-nft {
    margin-left: 0px;
  }

   



}
@media (max-width: 767px) {

  .Tabs_start table {
    display: -webkit-box;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px;
  }

  .collab-nft {
    width: 100%;
    display: block !important;
  }

  .collab_two {
    width: auto;
    padding: 20px;
  }

  .user-nft {
    margin-left: 0px;
  }

  .Tabs_start table thead tr th {
    min-width: 150px;
  }

}